* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}
.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.Mui-selected {
  color: black !important;
  font-weight: 600 !important;
}

.modalBox::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.modalBox::-webkit-scrollbar {
  width: 16px;
}
a {
  text-decoration: none;
}

.select-input {
  padding-bottom: 0px !important;
}

.assignment-column {
  width: 220px;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1400px) {
  .assignment-column {
    width: 250px;
  }
}

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.welcome-container img {
  position: absolute;
  z-index: 99;
  max-width: 120px;
  animation: breathing 5s ease-out infinite normal;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.loader {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.loader span:nth-child(1) {
  filter: blur(5px);
}

.loader span:nth-child(2) {
  filter: blur(10px);
}

.loader span:nth-child(3) {
  filter: blur(25px);
}

.loader span:nth-child(4) {
  filter: blur(50px);
}

.loader:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #f1f1f1;
  border: solid white 10px;
  border-radius: 50%;
}

.card {
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 5s ease, transform 5s ease;
}

.card.in.visible {
  opacity: 1;
  transform: translateY(0);
}
